<template>
    <div id="class_story">
        <inc_header></inc_header>

        <div class="container">
            <div class="banner"><el-image :src="banner.srcPc" fit="cover"></el-image></div>

            <div class="show">
                <div class="left">
                    <div class="left_nav">
                        <router-link to="/about_mitone">关于觅堂</router-link>
                        <router-link to="/course_arrangement">课程安排</router-link>
                        <router-link to="/our_teachers">师资团队</router-link>
                        <router-link to="/class_story">课堂花絮</router-link>
                    </div>
                </div>
                <div class="right">
                    <ul>
                        <li v-for="c in classStories" :key="c.storyId"><span class="post-date">{{ c.postDateFormat }}</span><router-link to="{ name:'class_story_show', params:{ id:c.storyId } }" target="_blank">{{ c.sign }}</router-link></li>
                    </ul>
                </div>
            </div>
        </div>

        <inc_footer></inc_footer>
    </div>
</template>

<style scoped>
    .banner { height:480px; }
    .banner .el-image { width:100%; height:100%; }

    .show { margin:60px 0; }
    .show:after { height:0; display:block; content:''; clear:both; visibility:hidden; }
    .show .left { width:310px; padding-right:40px; float:left; }
    .show .left .left_nav { width:100%; height:auto; padding:40px 0; background:#fcc; }
    .show .left .left_nav a { width:100%; height:40px; line-height:40px; border-bottom:1px solid #fff; color:#333; text-align:center; text-decoration:none; display:block; }
    .show .left .left_nav a:hover { color:#a00e15; font-weight:bold; background:#fff; }
    .show .left .left_nav a.focus { color:#a00e15; font-weight:bold; background:#fff; }
    .show .right { width:890px; float:left; }
    .show .right ul { margin:0; padding:0; }
    .show .right ul li { height:35px; line-height:35px; list-style:none; font-size:18px; color:#999; }
    .show .right ul li span.post-date { font-family:Arial; }
    .show .right ul li a { margin:0 0 0 25px; color:#999; text-decoration:none; }
    .show .right ul li a:hover { color:#000; }
</style>

<script>
    import inc_header from "../components/inc_header";
    import inc_footer from "../components/inc_footer";

    export default {
        name: "class_story",
        components: {
            inc_header,
            inc_footer
        },
        data() {
            return {
                banner: {},  // Banner
                classStories: []  // 课堂花絮
            }
        },
        created() {
            let that = this;

            // 获取“Banner”
            this.$axios.post(this.$global.config.serverApi+'/banner.php',{ action:"getByCategory", category:"classStory" }).then( function(response) {
                if (response.data.complete === true) {
                    that.banner = response.data.banner;
                } else {
                    throw new Error(response.data.error);
                }
            }).catch( function(error) {
                that.$store.commit('setAlert', error.message);  // 弹出错误提示
            });

            // 获取“课堂花絮”
            this.$axios.post(this.$global.config.serverApi+'/class_story.php',{ action:"list", page:0, rowsPerPage:0 }).then( function(response) {
                if (response.data.complete === true) {
                    that.classStories = response.data.classStories;
                } else {
                    throw new Error(response.data.error);
                }
            }).catch( function(error) {
                that.$store.commit('setAlert', error.message);  // 弹出错误提示
            });
        }
    }
</script>
